@charset "utf-8";
/* CSS Document */

@media (max-width: 1200px) 
{

}
@media (max-width: 1024px) 
{
	.menu-icon {
		display: block;
		height: auto;
		padding: 7px 0 7px 0;
		width: 40px;
		background: rgba(0,0,0,0.2);
		border: none;
	}

	#nav { float:none; margin:0 auto; text-align:center; position: relative;}
	#nav ul {display: none;	overflow: hidden;	width: 250px;	position: absolute;	top: 45px;	right: 0px;	z-index: 11;	background-color: #fff !important;	box-shadow: 0 0 4px #ccc;	border-radius: 10px;}
	#nav ul li {width: 100%;margin: 0px !important;	padding: 0px !important; border: none !important; border-bottom: 1px solid #ccc !important;}
	#nav ul li a {padding: 10px 0; color: #000; display: block;}
	#nav ul li a.btn {color: #fff !important;}

	.content-title h1 {font-size: 30px;}
	.section-title h1 {font-size: 18px;}

	#nav ul.open {
		display: block;
	}
	#tests .row [class*="col-md"] {
		flex: 0 0 33.33%;
		max-width: 33.33%;
	}

	.chartslist .row [class*="col-md"] {
		flex: 0 0 50%;
		max-width: 50%;
		margin-bottom: 30px;
	}
	.matric {
		margin-top: 0;
	}
	.matric .row [class*="col-md"] {
		flex: 0 0 100%;
		max-width: 100%;
		padding-bottom: 30px;
	}
	.pagewapper .cards {
		padding: 15px;
		height: auto;
	}

	#header #nav .users {
		display: none;
	}
	#nav ul .username {
		display: block;
	}
	#nav ul .rdblock {
		display: block;
	}
}

@media (max-width: 800px) 
{
	.col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9 
	{
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
	}

	.order-md-1, .order-md-2 {-ms-flex-order: inherit !important; order: inherit !important;}

	#tests .row [class*="col-md"]{
		flex: 0 0 50%;
		max-width: 50%;
	}
	.forms_boxs.contactinfo {
		max-width: 100%;
	}
	.footer-box {
		display: block;
	}
	.footer_links ul {
		padding: 0px;
		margin-top: 15px;
		justify-content: center;
	}
	.contactus-box {
		margin-top: 0;
	}
	#contactus .row .col-md-5, #contactus .row .col-md-7 {
		margin-top: 30px;
	}
	#tests .row [class*="col-md"] {
		flex: 0 0 50%;
		max-width: 50%;
	}
	.btnboxs {
		text-align: center;
		margin-top: 30px;
	}
	.auth-block.login .form-group {
		padding: 0;
	}
	.formsboxs {
		padding: 20px;
		border-radius: 10px;
	}
	#otpform .form-group {
		padding: 0;
	}
	#otpform input {
		margin: 0 8px;
		width: 44px !important;
		font-size: 16px;
	}
	.buttonbox {
		margin-top: 10px;
	}

	.button_box p {
		padding-left: 0;
		font-size: 16px;
		font-weight: 600;
	}
	.resultboxs {
		margin-top: 30px;
	}
	.col-md-12 .chart_card {
		margin-left: 0;
		width: 100%;
	}
	.pagetitles_box {
		display: block;
	}
	.pagewapper .right_boxs {
		margin-top: 20px;
	}
	#detailstestbox .col-md-6 {
		max-width: 100%;
	}
	#detailstestbox .col-md-6:first-child {
		order: 2;
	}
	#detailstestbox .col-md-6:nth-child(2) {
		order: 1;
		margin-bottom: 20px;
	}
	.profileboxs .button_boxs {
		padding: 0 0 30px 0;
	}
}

@media (max-width: 640px) 
{
	.chartslist .row [class*="col-md"] {
		flex: 0 0 100%;
		max-width: 100%;
	}
	.pagetitles_box h1 {
		font-size: 18px;
	}
	.pagetitles_box p, .pagetitles_box p a {
		font-size: 15px;
	}
	.chart_title h4 {
		font-size: 18px;
	}
	#header .users {
		margin-left: 15px;
	}

	.test_header {
		flex-wrap: wrap;
	}
	.test_header #timer {
		flex: 0 0 100%;
		order: 1;
		margin-bottom: 10px;
	}
	.test_header h4 {
		order: 2;
	}
	.pagewapper .right_boxs ul li p {
		font-size: 15px;
		line-height: 16px;
	}
	.pagewapper .colorboxs {
		width: 25px;
		height: 25px;
		flex: 0 0 25px;
		border-radius: 4px;
		margin-right: 5px;
	}
	.pagewapper .right_boxs ul li {
		padding: 0 10px;
	}
	.pagewapper .right_boxs ul {
		margin: 0px -10px;
	}
	.test_boxs {
		max-width: 180px;
		text-align: center;
	}
	.paymentdetails h1,
	.subscribe-block h1 {
		font-size: 25px;
		margin-bottom: 30px;
	}
	.subscribe-box {
		padding: 20px;
	}
	.subscribe-box h4 {
		font-size: 20px;
		margin-bottom: 6px;
	}
	.subscribe-box h2 {
		font-size: 25px;
		color: #000;
	}
	.subscribe-box .accessboxs {
		padding: 20px 0;
		margin: 20px 0;
	}
	.subscribe-box .accessboxs p {
		margin-bottom: 4px;
	}
	.subscribe-box .buttons .btn {
		padding: 10px !important;
	}
}

@media (max-width: 500px) 
{
	#tests .row [class*="col-md"] {
		flex: 0 0 100%;
		max-width: 100%;
	}
	.footer_links ul {
		margin: 0;
		display: block;
		margin-top: 15px;
	}
	.footer_links li {
		padding: 0;
		margin-top: 10px;
	}
	#header .logo-box {
		max-width: 100%;
		width: 100%;
		display: block;
		padding-right: 20px;
	}
	.question_list ul li {
		padding: 8px;
		flex: 0 0 20%;
		max-width: 20%;
	}
	.question_list ul li .indicats {
		width: 100%;
		aspect-ratio: 16/16;
	}
	
}