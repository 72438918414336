@charset "utf-8";
/* CSS Document */ 


/* font-family: 'Comme-Light';
font-family: 'Comme-Regular';
font-family: 'Comme-Medium';
font-family: 'Comme-SemiBold';
font-family: 'Comme-Bold'; */


* { -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; margin:0; padding:0; }
*:before, *:after { -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; }
body { margin:0; padding:0; font-family: 'Comme-Regular'; color: #241C15; -webkit-overflow-scrolling: touch;}

#root {
	overflow: hidden;
	width: 100% !important;
}

:focus { outline:none;}
ul, li, ol { list-style:none; padding: 0; margin: 0;}
a { text-decoration:none; -webkit-transition:0.5s; color: #494949; transition:0.5s;}
input[type="button"],input[type="submit"] { text-decoration:none; -webkit-transition:0.5s; transition:0.5s;}
a:hover{color:#0072ca; text-decoration: none; outline: none;}
a:focus {text-decoration: none; outline: none;}
a img, img { border:none; vertical-align:middle; max-width:100%; width:auto}

h1, h2, h3, h4, h5, h6 {font-family: 'Comme-Bold'; margin: 0px; padding: 0px; }
p {
	font-family: 'Comme-Regular';
	color: #241C15;
	font-size: 15px;
	margin-bottom: 15px;
}

:before, :after { -webkit-box-sizing:border-box; -moz-box-sizing:border-box; box-sizing:border-box }


input,
button,
select,
textarea { font-family: inherit; font-size: inherit; line-height: inherit; }

button, 
html input[type="button"], 
input[type="reset"], 
input[type="submit"] { cursor: pointer;  outline: none !important; text-decoration: none !important;}

button::-moz-focus-inner, 
input::-moz-focus-inner {  padding: 0;  border: 0; }

input[type="checkbox"], 
input[type="radio"] { -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; padding: 0; }

input[type="search"],
input[type="text"],
textarea { -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box;  }

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {  -webkit-appearance: none;}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {-webkit-appearance: none;   margin: 0;}


table { border-spacing: 0; border-collapse: collapse; }

.row:before, .row:after {display: inline-block !important;}

.container {max-width: 1200px; width: 100%; margin:0 auto; padding: 0px 15px;}
.container:before, 
.container:after { display:table; content:"";}
.container:after { clear:both;}

.container-fluid {max-width: 100%; width: 100%; margin:0 auto; padding: 0px 20px;}
.container-fluid:before, 
.container-fluid:after { display:table; content:"";}
.container-fluid:after { clear:both;}

.menu-icon { display:none; height:auto; padding:7px 0 7px 0; width:40px; background:rgba(0,0,0,0.2);}
.menu-icon span { display:block; margin:4px 10px; background:#fff; height:3px;}

::placeholder {color: #fff !important; opacity: 0.8 !important; font-size: 15px !important;}
:-ms-input-placeholder {color: #fff !important; opacity: 0.8 !important; font-size: 15px !important;}
::-ms-input-placeholder {color: #fff !important; opacity: 0.8 !important; font-size: 15px !important;}

.section-title h1 {
	color: #241C15;
	font-size: 20px;
	font-family: 'Comme-Medium';
	padding-left: 10px;
	margin-bottom: 20px;
	border-left: 4px solid #b60011;
}
.section-title.w h1 {
	color: #fff;
}
.content-title h1 {
	color: #B60011;
	font-size: 40px;
	font-family: 'Comme-SemiBold';
	margin-bottom: 10px;
}
.txtj {
	text-align: justify;
}
.txtc {
	text-align: center;
}
.txtr {
	text-align: right;
}
.txtl {
	text-align: left;
}

.btn.btn-red {
	background: #B60011 !important;
	color: #fff !important;
	font-family: 'Comme-Bold' !important;
	font-size: 14px !important;
	padding: 8px 15px;
	border-radius: 5px !important;
	border: 1px solid #B60011 !important;
}
.btn.btn-red-border {
	background: #fff !important;
	color: #000 !important;
	font-family: 'Comme-Bold' !important;
	font-size: 14px !important;
	padding: 8px 15px;
	border-radius: 5px !important;
	border: 1px solid #B60011 !important;
}
.btn.btn-red-border:hover {
	background: #B60011 !important;
	color: #fff !important;
}

.btn.btn-green {
    background: #64B66C !important;
    border: none;
	color: #fff !important;
	font-family: 'Comme-Bold' !important;
	font-size: 14px !important;
	padding: 8px 15px;
	border-radius: 5px !important;
}

.button_box p span.btn {
	margin: 0 5px;
	width: 90px;
}
.btn.btn-gray {
	background: transparent !important;
	color: #3E4954 !important;
	font-family: 'Comme-Bold' !important;
	font-size: 14px !important;
	padding: 8px 15px;
	border-radius: 5px !important;
	border: 1px solid #3E4954 !important;
}
button.isDisabled.btn.btn-red {
    opacity: 0.5;
    pointer-events: none;
}

#header {
	height: 82px;
}
#header.logins {
	height: 80.8px;
}
#header .header-block {
	padding: 15.6px 0px;
	z-index: 1111 !important;
	width: 100% !important;
	position: fixed;
	top: 0;
	left: 0;
	box-shadow: 0px 0px 4px 1px rgba(0,0,0,0.2);
	background-color: #fff;
}
#header .header-block .header-box {display: flex;	align-items: center; justify-content: space-between;}
#header .logo-box {
	max-width: 280px;
	width: 100%;
	display: block;
}
#header .logo-box a {display: block; }
#header .logo-box img {width: 100% !important;	height: auto !important;}
#header .main-nav {display: block; position: relative;}
#header.logins .header-block {
	background: #B60011;
	padding: 20px 0;
}
#header .users {
	display: flex;
	align-items: center;
	margin-left: 25px;
}

#header .dropdown-item {
	border-bottom: 1px solid #ccc;
	padding: 6px 10px;
}

#header .dropdown .dropdown-menu {
	padding: 0px !important;
	position: absolute !important;
	left: auto !important;
	inset: auto !important;
	right: 0px !important;
	transform: translate(0px) !important;
	top: 46px !important;
}
#header .dropdown-item .io {
	margin-right: 5px;
	display: inline-flex;
}
#header .users p {
	margin: 0;
	color: #000;
	padding-right: 10px;
}
#header #dropdown-basic {
	background: #b60011 !important;
	border: none !important;
	box-shadow: 0px 0px 4px #ccc !important;
	border-radius: 50% !important;
	padding: 10px;
	width: 40px;
	height: 40px;
}
#header .userio {
	color: #fff;
	font-size: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
}
#header #dropdown-basic::after {
	display: none
}






#nav ul {display: flex;	align-items: center;}
#nav ul li {display: block;	padding: 0 10px; margin: 0 10px;}
#nav ul li:first-child {padding-left: 0; margin-left: 0;}
#nav ul li:last-child {padding-right: 0; margin-right: 0;}
#nav ul li a {font-family: 'Comme-Regular'; color: #241C15; font-size: 16px; letter-spacing: 0.5px;}
#nav ul li.btns {margin-right: 0;	padding-right: 0;	margin-left: 0;}

#banner img {
	width: 100%;
	display: block;
}


.about-block {
	padding-top: 60px;
}
#tests .tests-block {
	padding: 60px 0;
}
#tests .row  {
	margin: 0 -10px;
}
#tests .row [class*="col-md"] {
	margin-bottom: 20px;
	padding: 0 10px;
}

#tests .cards {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 0;
	width: 100%;
	height: 100%;
	background: #01016C;
	padding: 10px 15px;
}
#tests .content {
	padding-right: 20px;
}
#tests .content h4 {
	color: #fff;
	font-size: 18px;
	font-family: 'Comme-Medium';
	margin-bottom: 5px;
}
#tests .content p {
	margin: 0;
	color: #fff;
}
#tests .icons {
	background: #fff;
	width: 28px;
	height: 28px;
	border-radius: 50%;
	text-align: center;
	line-height: 26px;
	color: #000;
	flex: 0 0 28px;
}
#tests div.cards {
	background: #959595;
}
#tests div.cards .icons {
	color: #959595;
}
#tests div.cards .content h4 {
	color: #000;
}
#tests div.cards .content p {
	color: #000;
}

.news-block {
	padding: 60px 0;
	background: #000;
}

.cardcontent h4 {
	font-family: 'Comme-SemiBold';
	font-size: 20px;
	color: #fff;
}
.datebox {
	display: flex;
	align-items: center;
	color: #fff;
	font-size: 20px;
	padding: 15px 0;
}
.datebox p {
	color: #fff;
	margin: 0;
	padding-left: 10px;
}
.cardcontent {
	padding-top: 15px;
}
.cardcontent p {
	color: #fff;
}
.cardimgs {
	position: relative;
	width: 100%;
	aspect-ratio: 16/18;
	overflow: hidden;
}
.cardimgs img {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	min-height: 100%;
	min-width: 100%;
}
.btnboxs .btn-white {
	background: #fff;
	color: #241C15;
	padding: 10px;
	box-shadow: none !important;
	border: none !important;
	border-radius: 0;
	font-size: 16px;
	font-family: 'Comme-Medium';
}
.flexend.row {
	align-items: flex-end;
}
.contactus-block {
	padding: 0 0 60px 0;
}
.contactus-box {
	margin-top: -55px;
}
.forms_boxs {
	padding: 20px;
	background: #B60011;
	border-radius: 15px;
	display: block;
}
.forms_boxs h4 {
	color: #fff;
	font-family: 'Comme-SemiBold';
	font-size: 20px;
	margin-bottom: 10px;
}
.forms_boxs p {
	color: #fff;
}
.forms_boxs .contact {
	display: flex;
	align-items: center;
	margin-bottom: 12px;
}
.forms_boxs .icons {
	display: flex;
	font-size: 18px;
	color: #fff;
	flex: 0 0 32px;
	max-width: 32px;
}
.forms_boxs .contact p, .forms_boxs .contact p a {
	color: #fff;
	margin: 0;
	line-height: normal;
}
.forms_boxs .contact:last-child {
	align-items: flex-start;
	margin: 0;
}
.forms_boxs .contact:last-child .icons {
	padding-top: 3px;
}
.forms_boxs.contactinfo {
	max-width: 310px;
}
.form-group {
	display: block;
	width: 100%;
	margin-bottom: 15px;
}
.form-group label {
	font-size: 15px;
	color: #fff;
	display: block;
	margin-bottom: 0;
}
.form-group .form-control {
	border: none !important;
	border-bottom: 1px solid #fff !important;
	border-radius: 0px !important;
	background: transparent !important;
	padding: 2px 0 8px 0;
	color: #fff !important;
	box-shadow: none !important;
	font-size: 15px !important;
}
textarea {
	resize: none !important;
}
.btnboxs {
	text-align: center;
	margin-top: 80px;
}
.fbio {
	color: #fff !important;
	font-size: 15px !important;
	display: flex;
	background: #395693;
	width: 32px;
	height: 32px;
	border-radius: 50%;
	align-items: center;
	justify-content: center;
}
.footer-block {
	background: #000;
	padding: 10px 0;
}
.footer-box {
	position: relative;
	text-align: center;
	padding: 4px 0;
	display: flex;
	justify-content: space-between;
}
.footer-box p {
	color: #fff;
	margin: 0;
}

.auth-block {
	background-image: url('../images/authbg.webp');
	padding: 60px 0;
	min-height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
}
.auth-forms {
	max-width: 600px;
	margin: 0 auto;
	width: 100%;
	display: block;
	text-align: center;
}
.formsboxs {
	position: relative;
	width: 100%;
	background: #fff;
	padding: 25px;
	border-radius: 15px;
	box-shadow: 0 0 4px #ccc;
}
.logo_box {
	width: 190px;
	height: 190px;
	background: #fff;
	border-radius: 50%;
	padding: 22px 34px;
	margin: 0 auto;
	margin-bottom: -94px;
}
.logo_box img {width: 100%;}



#auth label {
	color: #000;
	text-align: left;
	margin-bottom: 8px;
	display: flex;
	align-items: center;
}
#auth label .icon {
	color: #B60011;
	display: flex;
	align-items: center;
	font-size: 18px;
	margin-right: 6px;
}
.buttonbox .btn {
	display: block;
	width: 100%;
}
.linksbox p {
	margin: 0;
	margin-top: 15px;
}
.linksbox p a {
	color: #241C15;
	text-decoration: underline;
}
.formsboxs .form-group .form-control {
	background: #EFEFEF !important;
	border-radius: 6px !important;
	padding: 10px !important;
	color: #000 !important;
	height: auto !important;
}

.formsboxs .form-group .input-group .form-control {
	border-radius: 0 6px 6px 0px !important;
}
.formsboxs .form-group .input-group .input-group-text {
	border: none !important;
	background: #DBDBDB !important;
	border-radius: 6px 0 0 6px !important;
}
.formsboxs .form-group .form-control::placeholder {color: #000 !important;}
.formsboxs .form-group .form-control:-ms-input-placeholder {color: #000 !important;}
.formsboxs .form-group .form-control::-ms-input-placeholder {color: #000 !important;}

.formsboxs .titles h4 {
	font-size: 18px;
	font-family: 'Comme-SemiBold';
	color: #000;
	margin-bottom: 15px;
}
.formsboxs .titles {
	margin-bottom: 40px;
}
.formsboxs .titles p {
	color: #929292;
	margin: 0;
}
.buttonbox {
	margin-top: 40px;
}
.linksbtns {
	display: flex;
	justify-content: flex-end;
}
.linksbtns button {
	color: #000;
	font-family: 'Comme-Medium';
	background: transparent !important;
	border: none !important;
	outline: none !important;
	box-shadow: none !important;
	font-size: 14px !important;
	border-bottom: 1px solid #000 !important;
	margin-top: 7px;
}
.auth-block.login .form-group {
	padding: 40px 0;
}

.errormsg {
	text-align: left;
	font-size: 14px;
	color: #b60011;
	margin: 0;
}

.pageloder {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	justify-content: center;
	background: rgba(255,255,255,0.6);
	align-items: center;
	z-index: 11111111;
}
.pageloder img {
	max-width: 80px;
	display: block;
}

.custom_btns {
	padding-top: 120px;
}
.custom_btns .btn {
	width: 80px;
}
.pagewapper {
	background: #F7F2F2;
	min-height: calc(100vh - 92px);
	padding: 20px 0;
}
.pagetitles_box {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-bottom: 20px;
}
.pagetitles_box h1 {
	color: #241C15;
	font-size: 20px;
	font-family: 'Comme-SemiBold';
}
.pagetitles_box p, .pagetitles_box p a {
	color: #A3A3A3;
	font-size: 16px;
	font-family: 'Comme-SemiBold';
	margin: 0;
	padding-top: 2px;
}

.btn.btn-red.io {
	display: flex;
	align-items: center;
}
.cricleio {
	background: #fff;
	width: 20px;
	height: 20px;
	color: #b60011;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 20px;
	margin-right: 10px;
}

.chart_card {
	background: #FFFFFF;
	border-radius: 10px;
	padding: 18px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
	width: 100%;
	height: 100%;
}
.chart_card::after {
	content: "";
	display: block;
	position: absolute;
	top: 18px;
	left: 0;
	height: calc(100% - 36px);
	background: #b60011;
	width: 3px;
	border-radius: 0px 4px 4px 0;
}
.pagewapper .cards {
	background: #fff;
	padding: 20px;
	border-radius: 10px;
	height: 100%;
}
.chart_title p {
	font-family: 'Comme-SemiBold';
	font-size: 15px;
	margin-bottom: 10px;
}

.chart_title h4 {
	color: #241C15;
	font-size: 20px;
}
.chartsbox {
	padding-left: 20px;
	max-width: 100px;
}


.matric {
	margin-top: 30px;
	padding-bottom: 50px;
}
.pagewapper .tabels.cards {
	padding: 0;
}
.sc-kEjbdu {
	color: #fff !important;
}

#tests.cards {
	padding-bottom: 0;
	border-radius: 5px;
}
#tests .cards {
	border-radius: 0px;
	background-color: #959595;
}
#tests .cards.passed {
	background-color: #64B66C;
}
#tests .cards.failed {
	background-color: #F7736C;
}



.pagewapper .right_boxs ul {
	display: flex;
	align-items: center;
	margin: 0px -15px;
}
.pagewapper .right_boxs ul li {
	padding: 0 15px;
	display: flex;
	align-items: center;
}
.pagewapper .colorboxs {
	width: 35px;
	height: 35px;
	flex: 0 0 35px;
	border-radius: 4px;
	margin-right: 10px;
}
.pagewapper .right_boxs ul li p {
	color: #000000;
	font-size: 16px;
	font-family: 'Comme-Medium';
}

.pagetitles_box.pa-10 {
	padding: 15px 0;
}

.test_boxs p {
	margin: 0;
	color: #fff;
	font-family: 'Comme-Regular';
	padding: 10px 12px;
	border-radius: 5px;
}
.test_boxs p.passed {
	background: #64B66C;
}
.test_boxs p.failed {
	background: #F7736C;
}

#otpform  .form-group div {
	justify-content: center;
}
#otpform .form-group {
	padding: 50px 0 30px 0;
}
#otpform input {
	background: #EFEFEF;
	margin: 0 10px;
	width: 50px !important;
	aspect-ratio: 16/16;
	border-radius: 7px;
	color: #000000;
	font-family: 'Comme-Medium';
	font-size: 18px;
	border: 1px solid #ccc;
	box-shadow: none !important;
	outline: none !important;
}

/* .fade.alert.alert-danger.alert-dismissible.show {
	position: fixed;
	top: 50%;
	left: 50%;
	margin: 0;
	transform: translate(-50%, -50%);
} */

.fade.alert.alert-danger.alert-dismissible.show {
	max-width: 240px;
	text-align: left;
	margin: -20px auto;
	padding: 15px;
}
.fade.alert.alert-danger.alert-dismissible.show p {
	margin: 0;
}
#otpform .errormsg {
	text-align: center;
	margin: -10px 0;
}

#practice .practice-block {
	padding: 60px 0;
	min-height: calc(100vh - 123px);
	display: block;
}
.test_header h4 {
	font-family: 'Comme-Medium';
	font-size: 17px;
	color: #000;
}
.test_header p {
	margin: 0;
	color: #B60011;
	font-family: 'Comme-SemiBold';
}
.test_header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 20px;
}
#tests .icons .cloros {
	color: gray;
}
.radioboxs {
	position: relative;
	width: 100%;
	margin: 15px 0;
	overflow: hidden;
}
.radioboxs .rdobtns {
	background: #F4F4F4;
	border-radius: 6px;
	padding: 12px;
	display: flex;
	align-items: center;	
}
.radioboxs input {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
	z-index: 2;
}
.radioboxs .checkmark {
	flex: 0 0 18px;
	border: 1px solid #000;
	aspect-ratio: 16/16;
	border-radius: 50%;
	margin-right: 10px;
	position: relative;
	background: #F4F4F4;
}
.radioboxs .rdobtns p {
	margin: 0;
	color: #000;
}

.radioboxs input[data-val="0"]:checked ~ .rdobtns  {
	background: #F7736C;
}
.radioboxs input[data-val="1"]:checked ~ .rdobtns  {
	background: #64B66C;
}
.radioboxs input:checked ~ .rdobtns p {
	color: #fff;
}
.radioboxs input:checked ~ .rdobtns .checkmark::after {
	content: '';
	background-image: url('../images/close.png');	
	background-size: 100%;
	width: 8px;
	display: block;
	height: 8px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-repeat: no-repeat;
	z-index: 1;
	
}
.radioboxs input[data-val="1"] ~ .rdobtns .checkmark::after {
	background-image: url('../images/right.png');
	width: 10px;
	height: 8px;
}

.radioboxs.active .checkmark::after {
	content: '';
	display: block;
	background-image: url('../images/right.png');
	width: 10px;
	height: 8px;	
	background-size: 100%;	
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-repeat: no-repeat;
	z-index: 1;	
}

.radioboxs.active .rdobtns  {
	background: #64B66C;
}
.radioboxs.active .rdobtns p {
	color: #fff;
}

.incorrectbox {
	width: 100%;
	background: #F9F5DC;
	padding: 15px;
	border-radius: 10px;
	margin: 15px 0;
	display: none;
}
.incorrectbox h4 {
	color: #F7736C;
	font-family: 'Comme-SemiBold';
	font-size: 18px;
	margin-bottom: 8px;
}
.incorrectbox p {
	margin: 0;
}
fieldset button.btn-red {
	width: 100%;
	margin-top: 15px;
	display: none;
}

#msform fieldset:not(:first-of-type) {display: none}
#timer {
	display: flex;
	align-items: center;
}
#timer img {
	width: 20px;
	margin-right: 10px;
}



.question_list ul {
	display: flex;
	align-items: center;
	margin: 0px -8px;
	flex-wrap: wrap;
}
.question_list ul li {
	padding: 8px;
	display: block;
	list-style: none;
}
.question_list ul li .indicats {
	background: #F1F1F1;
	width: 46px;
	height: 46px;
	border: none !important;
	padding: 0;
	border-radius: 6px;
	box-shadow: none !important;
	outline: none !important;
	color: #626262;
	font-family: 'Cairo-Bold';
	font-size: 17px;
}
.question_list ul li .indicats.active {
	background: #01016C !important;
	color: #fff !important;
}
.questionsbox h4 {
	color: #000;
	font-family: 'Comme-Medium';
	font-size: 16px;
	margin-bottom: 8px;
}
#detailstestbox {
	background: #fff;
	padding: 20px;
	border-radius: 10px;
}
#detailstestbox .row {
	justify-content: space-between;
}
#detailstestbox .col-md-6 {
	max-width: 630px;
}
#detailstestbox .incorrectbox {
	display: block;
}
#testlistbox .formsbox:not(:first-of-type) {display: none}



.profileboxs .form-group .form-control {
	border: none !important;
	color: #000 !important;
	background: #fff !important;
	padding: 15px;
	border-radius: 8px !important;
	box-shadow: none !important;
}
.profileboxs .form-group label {
	color: #241C15;
	display: flex;
	align-items: center;
	margin-bottom: 10px;
}
.profileboxs .form-group label .icon {
	display: flex;
	align-items: center;
	margin-right: 5px;
	font-size: 18px;
}
.profileboxs .form-group .input-group .form-control {
	border-radius: 0 8px 8px 0 !important;
}
.profileboxs .form-group .input-group .input-group-text {
	background: #fff !important;
	border: none !important;
	border-right: 2px solid #000 !important;
	border-radius: 8px 0px 0px 8px !important;
}

.profileboxs .button_boxs {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 30px 0;
}
.profileboxs .button_boxs div{padding: 10px;}
.profileboxs .button_boxs .btn {
	padding: 10px !important;
	min-width: 100px;
}

#tests .cards {
    padding: 15px;
}
.pagewapper .tests-box {
    background: #fff;
    padding: 20px;
    border-radius: 10px;
}

.tabels.cards a {
	text-decoration: underline;
}

.custom_btns.btngroups {
	display: block;
	padding-top: 40px;
}
.paypals {
	max-width: 300px;
	margin: 0 auto;
	margin-bottom: 20px;
}
.custom_btns.btngroups .btn {
	width: 120px;
	padding: 10px;
	border: 2px solid #B60011 !important;
}

p.notes {
    color: #B60011;
    font-weight: bold;
    font-size: 17px;
    margin-bottom: 30px;
}


#nav {
	display: flex;
	align-items: center;
}

.profileboxs {
	max-width: 600px;
	margin: 0 auto;
}


.col-md-12 .chart_card {
    box-shadow: 0px 0px 4px #ccc;
    margin-left: 50px;
    width: calc(100% - 50px);
    margin-bottom: 20px;
    height: auto;
}

.button_box p {
    padding-left: 50px;
    font-size: 17px;
    font-weight: 600;
}


 .forms_boxs #conatcform p.errormsg {
	color: #000;
}

.footer_links ul {
	display: flex;
	padding-left: 20px;
	margin: 0 -10px;
	align-items: center;
	list-style-type: none;
}
.footer_links li {
	padding: 0 10px;
}
.footer_links ul li a {
	color: #fff;
	border-bottom: 1px solid #fff;
}


#contents .Content-block {
	padding: 40px 0;
	min-height: calc(100vh - 124px);
}



#notfound {
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	padding: 20px;
	width: 100%;
	height: 100vh;
}
#notfound p {
	font-size: 18px;
	font-weight: bold;
	color: #000;
	line-height: normal;
	margin-bottom: 20px;
}
#contents h1, #contents h2 {
	font-size: 27px;
	margin-bottom: 20px;
	color: #000;
}
#contents h3 {
	font-size: 22px;
	margin-bottom: 8px;
	color: #000;
	margin-top: 25px;
}
.subscribe-block {
	background: #fff;
	padding: 60px 0 30px 0;
	margin-bottom: -20px;
}
.subscribe-block h1 {
	text-align: center;
	font-size: 30px;
	margin-bottom: 35px;
}
.subscribe-block .col-md-6 {
	margin-bottom: 30px;
}
.subscribe-box {
	width: 100%;
	height: 100%;
	background: #fff;
	border: 1px solid #cccc;
	padding: 30px;
	box-shadow: 0 0 8px rgba(0,0,0,.2);
	border-radius: 8px;
	transition-timing-function: ease-out;
	transition-duration: .15s;
	transform: translateY(0);
}
.subscribe-box:hover {
	transition-timing-function: ease-out;
	transition-duration: .2s;
	transform: translateY(-5px);
	box-shadow: 0 14px 32px 0 rgba(0,0,0,.2);
}
.subscribe-box .accessboxs {
	padding: 30px 0 15px 0;
	margin: 30px 0;
	border-top: 1px solid;
	border-bottom: 1px solid;
	border-color: #ccc;
}
.subscribe-box h4 {
	font-size: 22px;
	color: #000;
	margin-bottom: 10px;
}
.subscribe-box h2 {
	font-size: 30px;
	color: #000;
}
.subscribe-box span {
	font-family: 'Comme-Regular';
	font-size: 16px;
}
.subscribe-box .accessboxs p {
	display: flex;
	align-items: center;
	color: #000;
}
.subscribe-box .accessboxs p.d {
	opacity: 0.4;
}
.subscribe-box .accessboxs p .io {
	padding-right: 10px;
	width: 25px;
}

.subscribe-box .buttons .btn {
	padding: 12px !important;
	width: 100%;
	display: block;
	font-size: 16px !important;
	letter-spacing: 0.2px;
}


#tests .cards.firsts p.fail {
	color: #b60011;
}
#tests .cards.firsts p.pass {
	color: #00ff19;
}




#nav ul .username {
	background: #000;
	padding: 10px;
	display: none;
}
#nav ul .username p {
	margin: 0;
	color: #fff;
}
#nav ul .rdblock {
	display: none;
}

.ofEne {
	overflow: visible !important;
}

.paymentdetails {
	padding: 80px 0 60px 0;
}
.paymentdetails h1 {
	text-align: center;
	font-size: 30px;
	margin-bottom: 35px;
}
.paymentdetails .table th {
	background: #000;
	color: #fff;
	padding: 12px;
}
.paymentdetails .table td {
	padding: 12px;
	text-transform: capitalize;
	color: #000;
	font-size: 16px;
	white-space: nowrap;
}

.subscribe-block .buttons.desable {
	opacity: 0.5;
	pointer-events: none;
}

.subscribe-box .subhed {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.subscribe-box .subhed .rdobox {
	position: relative;
	flex: 0 0 25px;
}
.subscribe-box .subhed .rdobox input {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
	z-index: 2;
}
.subscribe-box .subhed .rdobox .rdomark {
	border: 2px solid #ccc;
	width: 100%;
	aspect-ratio: 16/16;
	display: block;
	border-radius: 50%;
	background: #fff;
	position: relative;
	overflow: hidden;
}
.subscribe-box .subhed .rdobox .rdomark::after {
	content: '';
	display: none;
	position: absolute;
	background: #138013;
	width: 80%;
	aspect-ratio: 16/16;
	border-radius: 50%;
	top: 10%;
	left: 10%;
	z-index: 1;
}
.subscribe-box .subhed .rdobox input:checked ~ .rdomark {
	border-color: #138013;
}
.subscribe-box .subhed .rdobox input:checked ~ .rdomark::after {
	display: block;
}

.fade.alert.alert-danger.alert-dismissible.show {
	position: fixed;
	max-width: 450px;
	z-index: 11111;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: calc(100% - 30px);
	background: #fff !important;
	border-radius: 12px;
}
.overly {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1111;
	background: rgba(0,0,0,0.5);
}

.alert-heading.h4 {
	color: #000;
	font-size: 20px;
	font-family: 'Comme-SemiBold';
	padding-right: 50px;
	
}
.fade.alert.alert-danger.alert-dismissible.show .buttons {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 -10px;
}
.fade.alert.alert-danger.alert-dismissible.show .buttons div {
	padding: 0 10px;
}
.fade.alert.alert-danger.alert-dismissible.show .buttons div .btn {
	width: 90px;
}


.centerbutton {
	text-align: center;
}
.centerbutton .btn.btn-red {
	font-size: 16px !important;
	padding: 12px 15px;
	width: 240px;
}